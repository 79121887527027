














































































































































































































































































































































































































import { Component, Vue } from 'vue-property-decorator';
import Header from '@/components/header.vue'; // @ is an alias to /src
import Sidebar from '@/components/left-sidebar.vue'; // @ is an alias to /src
import Loader from '@/components/loader.vue'; // @ is an alias to /src
import HRMService from "../../../services/hrm-service";
const moment = require('moment')

@Component({
  components: {
    Header,
    Sidebar,
    Loader,
  },
})
export default class Employee extends Vue {
  loading = false;
  public fields = [
    { key: 'employee_code', label: 'code', sortable: true }, 
    { key: 'employee_title', label: 'title', sortable: false }, 
    { key: 'd1' }, { key: 'd2' },{ key: 'd3' }, { key: 'd4' },{ key: 'd5' }, { key: 'd6' },{ key: 'd7' }, { key: 'd8' },{ key: 'd9' }, { key: 'd10' },
    { key: 'd11' }, { key: 'd12' },{ key: 'd13' }, { key: 'd14' },{ key: 'd15' }, { key: 'd16' },{ key: 'd17' }, { key: 'd18' },{ key: 'd19' }, { key: 'd20' },
    { key: 'd21' }, { key: 'd22' },{ key: 'd23' }, { key: 'd24' },{ key: 'd25' }, { key: 'd26' },{ key: 'd27' }, { key: 'd28' },{ key: 'd29' }, { key: 'd30' }, 
    //  { key: 'd31' },
    { key :'P'}, 
    { key :'L'}, 
    { key :'OT'}, 
    { key :'LE'}, 
  ];
  public import_file = null
  params_date :any = {}
  import_params :any = {}
  create :any = {};
  ot :any = {};
  leave :any = {};
  late :any = {};

  public filter = null;
  public filterOn = [];
  public selected_items = [];
  public items:any = [];
  public currentPage = 1;
  get rows() {
    return this.selected_items.length;
  }

  public columns = [
    {field: "employee_code", label: "Code"},
    { field: "employee_title", label: "Title" },
    { field: "d1",label: "D1" },
    { field: "d2",label: "D2" },
    { field: "d3",label: "D3" },
    { field: "d4",label: "D4" },
    { field: "d5",label: "D5" },
    { field: "d6",label: "D6" },
    { field: "d7",label: "D7" },
    { field: "d8",label: "D8" },
    { field: "d9",label: "D9" },
    { field: "d10",label: "D10" },
    { field: "d11",label: "D11" },
    { field: "d12",label: "D12" },
    { field: "d13",label: "D13" },
    { field: "d14",label: "D14" },
    { field: "d15",label: "D15" },
    { field: "d16",label: "D16" },
    { field: "d17",label: "D17" },
    { field: "d18",label: "D18" },
    { field: "d19",label: "D19" },
    { field: "d20",label: "D20" },
    { field: "d21",label: "D21" },
    { field: "d22",label: "D22" },
    { field: "d23",label: "D23" },
    { field: "d24",label: "D24" },
    { field: "d25",label: "D25" },
    { field: "d26",label: "D26" },
    { field: "d27",label: "D27" },
    { field: "d28",label: "D28" },
    { field: "d29",label: "D29" },
    { field: "d30",label: "D30" }
    // {
    //   label: 'date',
    //   field: 'created_at',
    //   dataFormat: (value: any) => {
    //     return moment(String(value)).format('MM/DD/YYYY')
    //   }
    // }
  ];

  onFiltered(filteredItems: any) { }

  mounted() {}

  get_data(params: any){
    this.loading = true;
    HRMService.get_attendance_sheet_by(params)
      .then((response) => {
        this.items = response.data.result;
        this.selected_items = this.items;
        this.loading = false;
      })
      .catch((e) => {
        this.loading = false;
        console.log(e);
      });
  }

  refreshList() {
  }

  
  open_sidebar(params :any, type :any){
      if(type == 'create'){
        this.$root.$emit("bv::toggle::collapse", "sidebar-variant-present");
        this.create['employee_id'] = params.employee_id;
        this.create['employee_title'] = params.employee_title        
      }
      else if(type == 'late'){
        this.$root.$emit("bv::toggle::collapse", "sidebar-variant-late");
        this.late['employee_id'] = params.employee_id;
        this.late['employee_title'] = params.employee_title
      }
      else if(type == 'leave'){
        this.$root.$emit("bv::toggle::collapse", "sidebar-variant-leave");
        this.leave['employee_id'] = params.employee_id;
        this.leave['employee_title'] = params.employee_title
      }
      else if(type == 'ot'){
        this.$root.$emit("bv::toggle::collapse", "sidebar-variant-overtime");
        this.ot['employee_id'] = params.employee_id;
        this.ot['employee_title'] = params.employee_title
      }
      
      else{alert('Please validate the parameters')}
    }
    
  save(params :any, type :any){    
    if(type == 'create'){
      let date = new Date(params['check_in'])
      let year = date.getFullYear();
      let month = date.getMonth() + 1;
      let day = date.getDate();
      let obj = {
          day: day, 
          month: month, 
          year: year, 
          check_in: params['check_in'], 
          employee_id : params['employee_id'],
          employee_title: params['employee_title'],
          status : params['status'],
          type : 'create'      
      }
      this.create_attendance(obj);
        
     }
    else if(type == 'late'){
      let date = new Date(params['check_in'])
      let year = date.getFullYear();
      let month = date.getMonth() + 1;
      let day = date.getDate();
      let obj = {
          day: day, 
          month: month, 
          year: year, 
          check_in: params['check_in'], 
          check_out: params['check_out'], 
          employee_id : params['employee_id'] , 
          employee_title: params['employee_title'], 
          status : 'LT',
          type : 'late' 
      }
      this.create_attendance(obj);
    }
    else if(type == 'leave'){
      let date = new Date(params['check_in'])
      let year = date.getFullYear();
      let month = date.getMonth() + 1;
      let day = date.getDate();
      let obj = {
          day: day, 
          month: month, 
          year: year, 
          check_in: params['check_in'],
          check_out: params['check_in'], 
          employee_id : params['employee_id'] , 
          employee_title: params['employee_title'], 
          status : 'L',
          type : 'leave'      
      }

      this.create_attendance(obj);
    }
    else if(type == 'ot'){
      let date = new Date(params['check_in'])
      let year = date.getFullYear();
      let month = date.getMonth() + 1;
      let day = date.getDate();
      let obj = {
          day: day, 
          month: month, 
          year: year, 
          check_in: params['check_in'], 
          check_out: params['check_out'], 
          employee_id : params['employee_id'] , 
          employee_title: params['employee_title'], 
          status : 'OT',
          type : 'ot'      
      }
      this.create_attendance(obj);

    }else{
      alert('Please validate the parameters')
    }
  }

  create_attendance(obj: any){
    this.loading = true;
    HRMService.create_attendance(obj)
      .then((response) => {
        this.get_data(this.params_date)
        // this.items = response.data;
        this.loading = false;
      })
      .catch((e) => {
        this.loading = false;
        console.log(e);
      });
  }


  upload(params: any){
    this.loading = true;
    HRMService.import_attendance(params)
      .then((response) => {
        console.log(response)     
        this.loading = false;
      })
      .catch((e) => {
        this.loading = false;
        console.log(e);
      });
  }

}
